img {
    max-width: 100%;
}

body {
    font-family: 'Poppins', sans-serif !important;
}

/* common css */
a {
    text-decoration-line: none !important;
    color: unset;
}

.sub_heading {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    color: #2D2B2B;
}

/* body .container {
    max-width: 87%;
} */

/* header css */
header .dropdown-toggle {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF !important;
    flex-grow: 0;
}

header .dropdown-toggle {
    box-shadow: none !important;
    border: 0 !important;
}

header .dropdown li {
    padding: 5px 0;
}

header {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1024;
    padding: 2px 0;
    background: #2d2b2b;
}

header ul li {
    list-style: none;
    margin: 0 0 0 19px;
}

.header ul {
    margin: 0;
    padding: 0;
}

header ul li a:hover {
    color: #FF2E4C !important;
}

header ul li a,
header ul li .nav-link {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    flex-grow: 0;
}

header ul li a:hover,
header ul li .nav-link:hover,
header .nav-link:focus,
header .navbar-nav .show>.nav-link {
    color: #FF2E4C !important;
}

header a.dropdown-item,
header a.dropdown-item:hover {
    color: #2D2B2B !important;
}

header .login_btn {
    background: #FFFFFF;
    border-radius: 4px;
    padding: 5px 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FF2E4C;
    margin-left: 14px;
}

header .login_btn:hover {
    border: 1px solid #FFFFFF;
    background-color: transparent;
    border-radius: 4px;
    padding: 5px 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
}

header .support_btn {
    border: 1px solid #FFFFFF;
    background-color: transparent;
    border-radius: 4px;
    padding: 5px 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
}

header .support_btn:hover {
    background: #FFFFFF;
    border-radius: 4px;
    padding: 5px 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FF2E4C;
}

/* hero section css */
.hero_section {
    padding: 180px 0 60px;
    margin-bottom: 300px;
}

.hero_section h5 {
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    color: #5E5E5E;
}

.hero_section h1 {
    font-weight: 700;
    font-size: 55px;
    line-height: 82px;
    color: #2D2B2B;
}

.hero_section p {
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    color: #5E5E5E;
    width: 67%;
    margin-bottom: 36px;
}

.hero_section .hero_img {
    position: absolute;
    right: 0;
    top: 0;
    width: 62%;
    padding: 0;
}

/* why_section css */
.rotate-full {
    animation: infinite-spinning 8s infinite linear;
}


@keyframes infinite-spinning {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.why_section p,
.catch_up_section p,
.suggested_section p {
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    color: #5E5E5E;
}

.why_img_div {
    position: relative;
}

.why_img_div img.dot_image {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

.padding_left {
    padding-left: 100px;
}

.btn.main_btn {
    background: #FF2E4C;
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    padding: 14px 46px;
    margin-top: 23px;
    transition: ease 0.5s;
}

.hero_section .btn,
footer .btn {
    transition: ease 0.5s;
}

.btn.main_btn:hover,
.hero_section .btn:hover,
footer .btn:hover {
    transform: translate(0, -6px);
}

.btn.main_btn:hover {
    background-color: #de2640;
    color: white;
}

/* catch_up_section css */
.catch_up_section {
    margin: 60px 0;
}

.img_box {
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 20px;
    cursor: pointer;
}

.img_box img {
    transition: .2s ease;
    height: 270px;
    width: 100%;
    object-fit: cover;
}

.img_box:hover img {
    transform: scale(1.1);
    transition: .2s ease;
}

.catachup_box h4 {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: #2D2B2B;
}

.catachup_box small {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #5E5E5E;
}

/* search_venues css */
.search_venues form .form_bg {
    display: flex;
    justify-content: space-between;
}

.search_venues .react-multiple-carousel__arrow::before {
    filter: brightness(0) invert(1);
}

.search_venues .suggested_section {
    margin-bottom: 15px;
}

.search_venues .react-multi-carousel-dot.react-multi-carousel-dot--active button {
    background-color: white;
    opacity: 1;
}

.search_venues .react-multi-carousel-dot button {
    opacity: .3;
}

.search_venues .react-multi-carousel-dot-list {
    bottom: -55px !important;
}

.search_venues .flex1,
.search_venues .flex2 {
    width: 48%;
    position: relative;
    z-index: 0;
}

.search_venues form span {
    position: absolute;
    left: -22px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
}

.search_venues form select,
.search_venues form input {
    background: #FFFFFF;
    border-radius: 10px;
    height: 56px;
    padding-left: 32px;
    border: 0;
    box-shadow: none !important;
}

.search_venues form input::-webkit-input-placeholder {
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    color: #5E5E5E;
    cursor: pointer;
    position: relative;
}

.search_venues form input::-moz-placeholder,
.search_venues form input:-ms-input-placeholder,
.search_venues form input:-moz-placeholder {
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    color: #5E5E5E;
    cursor: pointer;
    position: relative;
}

.search_venues form select {
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    color: #5E5E5E;
    cursor: pointer;
    position: relative;
}

.search_venues .flex1:before {
    content: '';
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
    background-image: url('./images/arrow.svg');
    width: 17px;
    height: 9px;
    background-size: cover;
    z-index: 1025;
}


.search_venues form {
    background-image: url('./images/shape1.png');
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 20px;
    padding: 80px 75px;
    background-color: #bf245f;
}

/* suggested_section css */
.suggested_section {
    margin: 60px 0 120px;
    position: relative;
}

.suggested_section .suggested_div {
    position: absolute;
    top: 0;
    left: 10px;
}

.suggested_slider {
    padding-top: 93px;
}

.suggested_slider .react-multi-carousel-list {
    position: unset;
}

.suggested_slider .react-multiple-carousel__arrow--right {
    top: 0;
    display: block !important;
    opacity: 1 !important;
    width: unset;
    height: unset;
    background: transparent !important;
    right: 13px !important;
    z-index: 1;
}

._loading_overlay_wrapper {
    position: unset !important;
}

.suggested_slider .react-multiple-carousel__arrow--left {
    top: 0;
    left: unset;
    z-index: 1;
    right: 57px;
    opacity: 1 !important;
    width: unset;
    height: unset;
    background: transparent !important;
}

.react-multiple-carousel__arrow--left {
    transform: rotate(-180deg);
}

.react-multiple-carousel__arrow::before {
    content: '' !important;
    background-image: url('./images/arrow-right.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 30px;
    height: 17px;
}

.suggested_img {
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}

.absolute {
    position: absolute;
    bottom: 9px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    text-align: center;
}

.absolute h5 {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    color: #FFFFFF;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.absolute h6 {
    background: rgba(63, 63, 63, 0.69);
    border-radius: 4px;
    position: relative;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    display: inline-block;
    padding: 7px 20px 7px 51px;
}

.absolute h6 img {
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translate(0, -50%);
}

.react-multi-carousel-item {
    padding: 0 15px;
}

.suggested_img:hover img.main_img1 {
    transform: scale(1.1);
    transition: .3s ease;
}

.suggested_img img.main_img1 {
    width: 100%;
    height: 320px;
    object-fit: cover;
}

.suggested_slider .react-multiple-carousel__arrow--right:disabled,
.suggested_slider .react-multiple-carousel__arrow--left:disabled {
    opacity: .4 !important;
}

.react-multi-carousel-dot-list {
    bottom: -32px !important;
}

.react-multi-carousel-dot button {
    background: #EAEAEA;
    border-radius: 30px;
    border: 0 !important;
    width: 10px;
    height: 10px;
    margin: 0 4px;
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button {
    background: #FF2E4C;
}

/* limited_offer css */
.limited_offer {
    background-image: url('./images/offer_bg.png');
    background-size: cover;
    padding: 80px 75px;
    background-attachment: fixed;
    background-position: center;
    margin: 40px 0;
}

.limited_offer h3 {
    font-weight: 700;
    font-size: 62px;
    line-height: 71px;
    text-transform: uppercase;
    color: #FFFFFF;
    display: inline-flex;
    align-items: center;
}

.limited_offer h5 {
    font-weight: 500;
    font-size: 42px;
    line-height: 60px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
}

.limited_offer .btn.main_btn {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}

.limited_offer .btn.main_btn:hover img {
    transform: scale(1.1);
    transition: .3s ease;
}

.no_data img {
    max-width: 25%;
}

.no_data h5 {
    font-weight: 600;
    font-size: 20px;
    color: #2D2B2B;
    text-align: center;
}

/* footer css */
.social_links a {
    width: 36px;
    height: 36px;
    display: inline-flex;
    background: #525252;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    margin-right: 12px;
}

.social_links a i {
    font-size: 16px;
}

.social_links a:hover {
    background-color: #ff2e4c;
    color: #ffffff;
}

footer {
    background: #343434;
    padding: 60px 0 30px;
}

footer h5 {
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 23px;
}

.copy_right {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #FFFFFF;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    text-align: center;
    padding: 20px 0 0;
    margin-top: 30px;
}

footer ul {
    margin: 0;
    padding: 0;
}

footer ul li {
    list-style: none;
}

footer li a,
footer p {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 16px;
    display: block;
    color: #FFFFFF;
}

footer li a:hover {
    color: #FF2E4C;
}

.background_image {
    background-image: url('../Assets/images/body-bg.jpg');
    background-size: cover;
    position: relative;
    padding: 120px 0 0;
}

.background_image header span img {
    filter: brightness(0) invert(1) !important;
}

.catachup_box .react-multi-carousel-item {
    padding: 0;
}

.catachup_box .img_box img {
    border-radius: 10px;
}

.catachup_box .img_box h5 svg {
    width: 13px;
}

.catachup_box .img_box:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.81) 100%);
}

.catachup_box .swiper-pagination {
    bottom: 45px !important;
}

.catachup_box .img_box h5 {
    margin-top: 10px;
    color: #fff;
    font-size: 17px;
    position: absolute;
    bottom: 7px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    text-align: left;
    padding-left: 16px;
}

.catachup_box .swiper-pagination-bullet {
    background: #EAEAEA;
    border-radius: 30px;
    border: 0 !important;
    width: 10px;
    height: 10px;
    margin: 0 4px;
}

.catachup_box .react-multi-carousel-dot-list {
    bottom: 0px !important;
}

.catachup_box .react-multi-carousel-list {
    padding-bottom: 25px;
}

.catachup_box .img_box {
    margin-bottom: 5px;
}

.placeholder_box {
    position: absolute;
    bottom: 22px;
    left: 20px;
    width: 75%;
    background-color: #f2f2f2;
    border-radius: 100px;
    height: 10px;
}

.placeholder_div {
    height: 270px;
    width: 100%;
    background: linear-gradient(120deg,
            #e5e5e5 30%,
            #f2f2f2 38%,
            #f2f2f2 40%,
            #e5e5e5 48%);
    background-size: 300%;
    background-position: 100% 0;
    animation: anim 1.5s infinite;
    border-radius: 10px;
    animation: anim 1.5s infinite;
}

@keyframes anim {
    to {
        background-position: -100% 0;
    }
}
#agora_local{
    width: 100%;  
    height: 100%;
}
#agora_local video, .agora_remote video{
position: unset !important;
}
.media-box.media-box-new{
    position: relative;
}

.media-box-new .media-player{
    position: relative;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
}
.media-box-new .media-player img{
    object-fit: fill;
    height: 100%;
    width: 100%;
}

/* media query */
@media (max-width:1750px) {
    .hero_section {
        margin-bottom: 218px;
    }
}

@media (max-width:1550px) {
    .hero_section {
        margin-bottom: 118px;
    }
}

@media (max-width:1400px) {
    .search_venues .suggested_img img.main_img1 {
        height: 268px;
    }

    .search_venues .absolute h5 {
        font-size: 21px;
    }

    .hero_section {
        margin-bottom: 118px;
    }

    .hero_section h1 {
        font-size: 46px;
        line-height: 71px;
    }

    .hero_section p {
        width: 77%;
    }

    .hero_section .hero_img {
        width: 66%;
    }
}

@media (max-width:1199px) {
    .placeholder_div {
        height: 222px;
    }

    .search_venues .suggested_img img.main_img1 {
        height: 230px;
    }

    .search_venues .absolute h5 {
        font-size: 19px;
    }

    .sub_heading {
        font-size: 32px;
        margin-bottom: 0;
        line-height: 49px;
    }

    .limited_offer h3 {
        font-size: 42px;
    }

    .why_section p {
        margin-top: 24px;
    }

    .suggested_img img.main_img1 {
        height: 267px;
    }

    .img_box img {
        height: 222px;
    }

    .absolute h5 {
        font-size: 20px;
        margin-bottom: 2px;
    }

    .absolute h6 img {
        max-width: 24px;
    }

    .absolute h6 {
        font-size: 14px;
        padding: 7px 20px 7px 41px;
    }

    .search_venues form {
        padding: 59px 75px;
    }

    .limited_offer h5 {
        font-size: 32px;
        line-height: 54px;
    }

    .limited_offer .btn.main_btn {
        padding: 9px 30px;
    }

    .hero_section .hero_img {
        width: 50%;
    }

    .hero_section {
        margin-bottom: 57px;
    }

    header ul li a,
    header ul li .nav-link {
        color: #000000;
    }

    header .support_btn {
        border: 1px solid #fb2d4c;
        background-color: #fb2d4c;
        padding: 8px 20px;
    }

    header .login_btn {
        border: 1px solid #fb2d4c;
        padding: 8px 20px;
    }

    footer h5 {
        font-size: 19px
    }

    footer li a,
    footer p {
        font-size: 14px;
    }

    footer img[alt="logo"] {
        max-width: 60%;
    }

}

@media (max-width:991px) {
    .no_data img {
        max-width: 30%;
    }

    .navbar-collapse ul {
        display: block !important;
    }

    header .dropdown li {
        padding: 5px 12px;
    }

    header .dropdown li:last-child {
        margin-bottom: 0 !important;
    }

    header .dropdown-toggle {
        color: #000000 !important;
    }

    .navbar-brand img {
        max-width: 80%;
    }

    header .navbar-toggler {
        box-shadow: none !important;
        padding: 3px 6px;
        background: #fb2d4c;
        border: 0;
    }

    header .navbar-toggler span {
        filter: brightness(0) invert(1);
        opacity: 1;
    }

    header {
        background-color: #f9f9f9;
        padding: 3px 0;
        box-shadow: 0 2px 3px rgb(57 63 72 / 12%);
    }

    .navbar-nav ul {
        flex-direction: column;
        padding: 0;
        margin: 0;
    }

    header ul li {
        margin: 0 0 17px 0px;
    }

    .center_content {
        text-align: center;
        margin-bottom: 45px;
    }

    .padding_left {
        padding-left: 40px;
    }

    .search_venues form {
        padding: 50px 49px;
    }

    .search_venues .flex1,
    .search_venues .flex2 {
        width: 45%;
    }

    .hero_section p {
        width: 100%;
    }

    .hero_section {
        margin-bottom: 0;
    }

    .why_img_div {
        margin-bottom: 20px;
    }
}

@media (max-width:767px) {
    .no_data img {
        max-width: 40%;
    }

    .search_venues .flex1,
    .search_venues .flex2 {
        width: 90%;
    }

    .search_venues .flex1 {
        margin-bottom: 20px;
    }

    .sub_heading img,
    .limited_offer h3 img {
        max-width: 40px;
    }

    .limited_offer h5 {
        font-size: 26px;
        line-height: 40px;
    }

    .limited_offer h3 {
        font-size: 31px;
        line-height: 40px;
    }

    .limited_offer {
        background-position: center;
    }

    .sub_heading {
        font-size: 27px;
    }

    .search_venues form .form_bg {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width:575px) {
    .no_data img {
        max-width: 50%;
    }

    .no_data {
        margin-top: 0 !important;
    }

    .no_data h5 {
        font-size: 16px;
    }

    header .container {
        width: 90%;
    }

    .search_venues form {
        padding: 50px 39px;
    }

    footer img[alt="logo"] {
        max-width: 86%;
    }

    .copy_right,
    footer li a,
    footer p {
        font-size: 13px;
    }

    .why_img_div {
        max-width: 70%;
        margin: auto;
    }

    .why_img_div img.dot_image {
        max-width: 50%;
    }

    .limited_offer {
        padding: 59px 41px;
    }

    .limited_offer h5 br {
        display: none;
    }

    .suggested_img img.main_img1 {
        height: 310px;
    }
}

@media (max-width:485px) {
    .center_content img {
        width: 111px;
    }

    .hero_section .btn {
        display: block;
        text-align: center;
        margin: 0 auto 10px !important;
    }

    .search_venues .suggested_section .sub_heading {
        line-height: 28px;
    }

    .search_venues .suggested_slider .react-multiple-carousel__arrow--left,
    .search_venues .suggested_slider .react-multiple-carousel__arrow--right {
        top: 42px;
    }

    .limited_offer {
        padding: 59px 19px;
    }

    .hero_section h1 {
        font-size: 29px;
        line-height: 43px;
    }

    .btn.main_btn {
        padding: 10px 32px;
    }

    .catch_up_section .col-6 {
        max-width: 100%;
        width: 100%;
    }

    .search_venues form {
        padding: 50px 14px 50px 32px;
    }

    .suggested_section .suggested_div {
        position: unset;
        padding: 0 19px;
    }

    .suggested_slider {
        padding-top: 17px;
    }

    .limited_offer .btn.main_btn {
        padding: 10px 19px;
    }

    .limited_offer .btn.main_btn img {
        max-width: 29px;
    }

    .navbar-brand img {
        max-width: 70%;
    }

    .hero_section {
        padding: 141px 0 44px;
    }

    .sub_heading {
        font-size: 23px;
    }

    .padding_left {
        padding-left: 10px;
    }

    .why_section p,
    .catch_up_section p,
    .suggested_section p {
        font-size: 13px;
        margin-top: 3px !important;
    }

    .limited_offer h3 {
        font-size: 27px;
        line-height: 40px;
    }

    .limited_offer h5 {
        font-size: 18px;
        line-height: 30px;
    }

    .catch_up_section {
        margin: 30px 0;
    }

    .sub_heading img,
    .limited_offer h3 img {
        max-width: 29px;
    }

    .suggested_slider .react-multiple-carousel__arrow--left,
    .suggested_slider .react-multiple-carousel__arrow--right {
        top: 121px;
    }

    .hero_section h1 br {
        display: none;
    }

    .suggested_section {
        margin: 30px 0 55px;
    }

    footer h5 {
        font-size: 16px;
    }
}

.onlyaudio video{
    display: none !important;
}
.onlyaudio img{
    display: block !important;
    width: 100% !important;
}
.onlyvideo video{
    display: block !important;
}
.onlyvideo img{
    display: none !important;
}